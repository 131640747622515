(function ($) {
  Drupal.behaviors.contentBlockSplitWidthHeroStickyV1 = {
    attach: function (context) {
      var $heroSticky = $('.split-width-hero-sticky', context);
      var $heroStickyContainer = $('.split-width-hero-sticky__wrapper', $heroSticky)
      var $heroStickyMedia = $('.split-width-hero-sticky__column--media', $heroSticky);
      var $heroStickyLogo = $('.split-width-hero-sticky__logo', $heroSticky);
      var mediaInitialBot;

      $(window).on('load', function () {
        mediaInitialBot = $heroStickyMedia[0].getBoundingClientRect().bottom;
      })
      $(window).on(
        'scroll resize',
        _.throttle(function () {
          if (
            $heroStickyContainer[0].getBoundingClientRect().bottom <=
            $heroStickyMedia[0].getBoundingClientRect().bottom
          ) {
            $heroStickyContainer.addClass('split-width-hero-sticky__wrapper--scrolled');
          }
          if ($heroStickyMedia[0].getBoundingClientRect().bottom >= mediaInitialBot) {
            $heroStickyContainer.removeClass('split-width-hero-sticky__wrapper--scrolled');
          }
        }, 10)
      );
    }
  };
})(jQuery);
